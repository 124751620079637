import React, { useState } from "react";
import { Scanner } from "@yudiel/react-qr-scanner";
import { useNavigate } from "react-router-dom";

const ScannerQr = () => {
  const navigate = useNavigate();
  const [scanResult, setScanResult] = useState(null);

  const handleScan = (result) => {
    if (result && result.length > 0) {
      const firstResult = result[0]; // Get the first element of the array
      console.log(firstResult);
      setScanResult(firstResult);

      if (firstResult.rawValue === "IONIQ6") {
        // Compare with rawValue
        navigate("/waiting-room", { replace: true });
      }
    }
  };

  const handleError = (error) => {
    console.error("Error while scanning:", error);
    // Handle any errors, like displaying an error message
  };

  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <h1 className="text-3xl font-semibold mb-4">Asus Doorprize</h1>
      <div className="bg-gray-200 p-4 rounded-lg shadow-lg">
        <Scanner
          onScan={handleScan}
          onError={handleError}
          style={{ width: "140px", height: "140px" }} // Adjust size as needed
        />
      </div>
      {scanResult && (
        <div className="mt-4">
          <p className="text-lg font-semibold">Scanned QR Code:</p>
          <p className="mt-2">{scanResult.rawValue}</p>
        </div>
      )}
    </div>
  );
};

export default ScannerQr;
