import React, { useState } from "react";
import BgVideo from "../assets/bg2.mp4";
import BgMusic from "../assets/music2.wav";
import Bg from "../assets/bg1.jpg";

const WaitingRoom = () => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => {
    setIsOpen(true);
  };

  return (
    <div
      className="w-screen h-screen relative"
      style={{
        backgroundImage: `url(${Bg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      {!isOpen && (
        <button
          onClick={handleClick}
          className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 px-10 py-10 mt-7 text-2xl font-medium text-white bg-blue-500 rounded-full shadow-btn-combo uppercase"
        >
          Click here to open!
        </button>
      )}
      {isOpen && (
        <>
          <video
            src={BgVideo}
            autoPlay
            muted
            className="w-full h-full object-cover"
          />
          <audio id="musicplayer" autoPlay>
            <source src={BgMusic} />
          </audio>
        </>
      )}
    </div>
  );
};

export default WaitingRoom;
