import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import LoadingScreen from "./layouts/Loading";
import Register from "./layouts/Register";
import MainVideo from "./layouts/MainVideo";
import Scanner from "./layouts/Scanner";
import WaitingRoom from "./layouts/WaitingRoom";
import Alternative from "./layouts/Alternative";

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const socket = new WebSocket("wss://avs14.api.mahakreasi.com/");

    socket.onopen = () => {
      console.log("WebSocket connection established");
      const intervalId = setInterval(() => {
        socket.send(JSON.stringify({ action: "getServerStatus" }));
      }, 1000);

      socket.onmessage = (event) => {
        const message = JSON.parse(event.data);
        if (message.status === 404) {
          setLoading(true);
          window.location.href =
            "https://asus-vivobook.doorprize.mahakreasi.com/";
        } else if (message.status === 200) {
          setLoading(false);
          clearInterval(intervalId);
        }
      };

      socket.onerror = (error) => {
        console.error("WebSocket error:", error);
        clearInterval(intervalId); // Stop interval on error
      };

      socket.onclose = () => {
        console.log("WebSocket connection closed");
        clearInterval(intervalId); // Stop interval on close
      };
    };

    return () => {
      socket.close();
    };
  }, []);

  return (
    <div className="h-screen flex justify-center align-middle">
      <Router>
        {loading ? (
          <LoadingScreen />
        ) : (
          <Routes>
            <Route path="/" exact element={<Register />} />
            <Route path="/main" exact element={<MainVideo />} />
            <Route path="/scanner" exact element={<Scanner />} />
            <Route path="/waiting-room" exact element={<WaitingRoom />} />
            <Route path="/alternative" exact element={<Alternative />} />
            <Route path="/loading" exact element={<LoadingScreen />} />
          </Routes>
        )}
      </Router>
    </div>
  );
}

export default App;
